import { Injectable }         from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import {FirebaseUserRescueOrg, UserOrgDetails, GlobalUserRoles, UserState, UserRoles} from "../user/user.store";

export interface RescueOrgInUser {
  id: string;
  name: string;
  favourite: boolean;
  role: UserRoles;
}

export interface FirebaseUserWithConnectedRescueOrgs {
  fullName: string;
  phone: string;
  globalRoles: GlobalUserRoles;
  email: string;
  connectedOrgs: FirebaseUserRescueOrg;
}

export interface OrganizationUser {
  id: string;
  fullName: string;
  email: string;
  phone?: string;
  role: UserRoles;
}

export interface RescueOrgState {
  name: string;
  totalAnimals: number;
  totalActiveAnimals: number;
  address: string;
  bank: string;
  bankNumber: string;
  city: string;
  door: string;
  email: string;
  logoURL: string;
  phone: string;
  tax: string;
  website: string;
  zip: string;
}

export function createInitialState(): RescueOrgState {
  return {
    address: '',
    bank: '',
    bankNumber: '',
    city: '',
    door: '',
    email: '',
    logoURL: '',
    phone: '',
    tax: '',
    website: '',
    zip: '',
    name: '',
    totalAnimals: 0,
    totalActiveAnimals: 0,
  }
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'rescue-org', resettable: true })
export class RescueOrgStore extends Store<RescueOrgState> {
  constructor() {
    super(createInitialState());
  }
}
